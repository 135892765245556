@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face{
    font-family: "Poppins-Light";
    src: local("Poppins-Light"),
    url('./Fonts/Poppins-Light.ttf') format("truetype");

}

@font-face{
    font-family: "Poppins-Bold";
    src: local("Poppins-Bold"),
    url('./Fonts/Poppins-Bold.ttf') format("truetype");

}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}


/* Nasconde la barra di scorrimento */
.scrollbar-hide::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}